<template>
  <app-list-view
    server-side
    show-group-by
    app="revenue"
    model="revenuetype"
    api-url="revenue/revenue-type/"
    :title="$t('menu.revenueType')"
    :createTo="{ name: 'revenueTypeCreate', query: $route.query }"
    :editTo="{ name: 'revenueTypeEdit' }"
    :headers="headers"
    :query="$route.query"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'revenueCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.revenueTypeName'), value: 'name' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'revenueType'
    })
  }
}
</script>
